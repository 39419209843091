<template>
    <div>
        <v-container class="pb-0">
            <div>
                <v-breadcrumbs class="pl-0 pb-1" :items="breadcrumbs">
                    <v-icon slot="divider" class="breadcrumbs-chevro">fas fa-chevron-right</v-icon>
                </v-breadcrumbs>

                <div v-if="!hidePoints" class="section-title">{{ $t('views.Prizes.title') }}</div>
                <div v-else class="section-title">{{ $t('views.Prizes.title_hide_points') }}</div>
            </div>

            <CustomerBalance v-if="$vuetify.breakpoint.xs" class="mt-8" />
        </v-container>

        <v-container class="pt-0" :class="$vuetify.breakpoint.smAndDown ? 'px-3':''">
            <v-col class="px-0 ">
                <v-tabs
                    v-model="tab"
                    height="40px"
                    color="primary"
                    slider-color="primary"
                    background-color="background"
                    class="sm-4 mb-3"
                    show-arrows
                >
                    <v-tab
                        v-if="!hideCashback"
                        key="list"
                        class="tab-title px-2"
                        active-class="tab-title-active"
                    >
                        {{ listTabTitle }}
                    </v-tab>

                    <v-tab
                        key="withdrawable"
                        class="tab-title px-2"
                        active-class="tab-title-active"
                    >
                        {{ withdrawableTabTitle }}
                    </v-tab>

                    <v-tab
                        key="withdrawn"
                        class="tab-title px-2"
                        active-class="tab-title-active"
                    >
                        {{ withdrawnTabTitle }}
                    </v-tab>

                    <v-tab
                        key="expired"
                        class="tab-title px-2"
                        active-class="tab-title-active"
                    >
                        {{ expiredTabTitle }}
                    </v-tab>
                </v-tabs>
                <!-- [TODO] implementar filtros categoria e preços -->
                <!-- <div v-if="verifyFiltersExists" class="d-flex justify-end px-10 mt-4">
                    <span class="body-2 filter font-weight-medium text-uppercase"
                        :class="showFilter ? '' : 'secondary_font--text'"
                        @click="showFilter = !showFilter" 
                    >
                        {{ $t('views.Prizes.filters.title') }}
                        <v-icon class="ml-2" color="primary">$cp_filter</v-icon>
                    </span>
                </div> -->
                
                <!-- [TODO] implementar filtros categoria e preços -->
                <v-row>
                    <!-- Componentizar -->
                    <!-- <v-col 
                            cols="12" md="2" sm="12" 
                            v-show="showFilter && verifyFiltersExists" 
                            :class="$vuetify.breakpoint.smAndDown? 'mt-4 px-3': 'mt-12'"
                        >
                        <div class="mb-2">
                            {{ $t('views.Prizes.filters.price') }}
                        </div>

                        <v-col v-if="categories.length" class="px-0" :class="$vuetify.breakpoint.mdOnly ? 'pl-4':''">
                            <div class="mb-3">{{ $t('views.Prizes.filters.category') }}</div>
                            <v-list-item-group>
                                <v-list-item v-for="(category, index) of categories" :key="index" class="px-0">
                                <template v-slot:default="{ active }">
                                        <v-list-item-action class="my-0 mx-0 ml-n1">
                                            <v-checkbox
                                                disabled
                                                class="mr-3"
                                                :input-value="active"
                                                color="primary"
                                                dense
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-title class="secondary_font--text py-0 ml-n1">
                                            {{ category.name }}
                                        </v-list-item-title>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-col>
                    </v-col> -->

                    <v-col>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-if="!hideCashback" key="list">
                                <v-card flat class="background"> 
                                    <v-card-text class="px-0">
                                        <PrizesList 
                                            ref="list"
                                            @bought="renderWithdrawable"
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <v-tab-item key="withdrawable">
                                <v-card flat class="background"> 
                                    <v-card-text class="px-0">
                                        <PrizesHistory
                                            ref="withdrawable"
                                            type="WITHDRAWABLE"
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <v-tab-item key="withdrawn">
                                <v-card flat class="background"> 
                                    <v-card-text class="px-0">
                                        <PrizesHistory
                                            ref="withdrawn"
                                            type="WITHDRAWN"
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <v-tab-item key="expired">
                                <v-card flat class="background"> 
                                    <v-card-text class="px-0">
                                        <PrizesHistory
                                            ref="expired"
                                            type="EXPIRED"
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-col>
        </v-container>
    </div>
</template>

<script>
// import PrizesFavorites from './PrizesFavorites'
import PrizesHistory   from './PrizesHistory'
import PrizesList      from './PrizesList'
import Voucher         from '@/models/Voucher'
import Prize           from '@/models/Prize'
import CustomerBalance from '@/components/structure/balance/CustomerBalance'
import { mapGetters }  from 'vuex'

export default {
    name: 'PrizesView',
    components: { CustomerBalance, PrizesList, PrizesHistory },
    data: (vm) => {
        return {
            breadcrumbs: [
                { text: vm.$t('drawer.dashboard'), to: { name: 'home' } },
                { text: vm.$t('drawer.prizes')   , disabled: true },
            ],
            tab: 0,
            items: [],
            vouchersCount: 0,
            prizesCount: 0,
            tabRefs: [],
        }
    },
    async created() {
        await this.setVouchersCount()
        await this.setPrizesCount()

        this.setTabRefs()

        if (this.$route.query && this.$route.query.tab) {
            this.setTabByRef(this.$route.query.tab)
        }
    },
    methods: {
        async fetchPrizes() {
            this.loading = true
            this.loading = false
        },
        async setVouchersCount() {
            const { Count: count } = await Voucher.count({
                customer_id: this.user.id,
            })
            this.vouchersCount = count
        },
        async setPrizesCount() {
            const { Count: count } = await Prize.count()
            this.prizesCount = count
        },
        renderWithdrawable() {
            this.setVouchersCount()

            // Se o componente ainda não foi inicializado, não é necessário
            let withdrawable = this.$refs.withdrawable
            if (!withdrawable || !withdrawable[0])
                return

            withdrawable[0].refresh()
        },
        setTabByRef(tabRef) {
            this.tab = this.tabRefs.findIndex(ref => ref === tabRef)
        },
        setTabRefs() {
            let tabRefs = []
            if (!this.hideCashback) {
                tabRefs = tabRefs.concat([
                    'list',
                ])
            }

            tabRefs = tabRefs.concat([
                'withdrawable',
                'withdrawn',
                'expired',
            ])

            this.tabRefs = tabRefs
        },
    },
    computed: {
        ...mapGetters({
            hideCashback: 'company/hide_cashback',
            hidePoints: 'company/hide_points',
            user: 'auth/user',
        }),
        listTabTitle() {
            return this.$t('views.Prizes.tabs.list.tab') + ' (' + this.prizesCount + ')'
        },
        withdrawableTabTitle() {
            return this.$t('views.Prizes.tabs.history_withdrawable.tab') + ' (' + this.vouchersCount + ')'
        },
        withdrawnTabTitle() {
            return this.$t('views.Prizes.tabs.history_withdrawn.tab')
        },
        expiredTabTitle() {
            return this.$t('views.Prizes.tabs.history_expired.tab')
        },
    },
}
</script>

<style>
.v-icon.v-icon--dense {
    font-size: 18px !important;
}

.v-input--selection-controls__ripple {
    display: none !important;
}
</style>

<style scoped>
.v-list-item {
    min-height: 24px !important;
}

.filter {
    cursor: pointer;
}
</style>