var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pb-0" },
        [
          _c(
            "div",
            [
              _c(
                "v-breadcrumbs",
                { staticClass: "pl-0 pb-1", attrs: { items: _vm.breadcrumbs } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "breadcrumbs-chevro",
                      attrs: { slot: "divider" },
                      slot: "divider"
                    },
                    [_vm._v("fas fa-chevron-right")]
                  )
                ],
                1
              ),
              !_vm.hidePoints
                ? _c("div", { staticClass: "section-title" }, [
                    _vm._v(_vm._s(_vm.$t("views.Prizes.title")))
                  ])
                : _c("div", { staticClass: "section-title" }, [
                    _vm._v(_vm._s(_vm.$t("views.Prizes.title_hide_points")))
                  ])
            ],
            1
          ),
          _vm.$vuetify.breakpoint.xs
            ? _c("CustomerBalance", { staticClass: "mt-8" })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "pt-0",
          class: _vm.$vuetify.breakpoint.smAndDown ? "px-3" : ""
        },
        [
          _c(
            "v-col",
            { staticClass: "px-0 " },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "sm-4 mb-3",
                  attrs: {
                    height: "40px",
                    color: "primary",
                    "slider-color": "primary",
                    "background-color": "background",
                    "show-arrows": ""
                  },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  !_vm.hideCashback
                    ? _c(
                        "v-tab",
                        {
                          key: "list",
                          staticClass: "tab-title px-2",
                          attrs: { "active-class": "tab-title-active" }
                        },
                        [_vm._v(" " + _vm._s(_vm.listTabTitle) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "v-tab",
                    {
                      key: "withdrawable",
                      staticClass: "tab-title px-2",
                      attrs: { "active-class": "tab-title-active" }
                    },
                    [_vm._v(" " + _vm._s(_vm.withdrawableTabTitle) + " ")]
                  ),
                  _c(
                    "v-tab",
                    {
                      key: "withdrawn",
                      staticClass: "tab-title px-2",
                      attrs: { "active-class": "tab-title-active" }
                    },
                    [_vm._v(" " + _vm._s(_vm.withdrawnTabTitle) + " ")]
                  ),
                  _c(
                    "v-tab",
                    {
                      key: "expired",
                      staticClass: "tab-title px-2",
                      attrs: { "active-class": "tab-title-active" }
                    },
                    [_vm._v(" " + _vm._s(_vm.expiredTabTitle) + " ")]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab"
                          }
                        },
                        [
                          !_vm.hideCashback
                            ? _c(
                                "v-tab-item",
                                { key: "list" },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "background",
                                      attrs: { flat: "" }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "px-0" },
                                        [
                                          _c("PrizesList", {
                                            ref: "list",
                                            on: {
                                              bought: _vm.renderWithdrawable
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-tab-item",
                            { key: "withdrawable" },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "background",
                                  attrs: { flat: "" }
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-0" },
                                    [
                                      _c("PrizesHistory", {
                                        ref: "withdrawable",
                                        attrs: { type: "WITHDRAWABLE" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { key: "withdrawn" },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "background",
                                  attrs: { flat: "" }
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-0" },
                                    [
                                      _c("PrizesHistory", {
                                        ref: "withdrawn",
                                        attrs: { type: "WITHDRAWN" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { key: "expired" },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "background",
                                  attrs: { flat: "" }
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-0" },
                                    [
                                      _c("PrizesHistory", {
                                        ref: "expired",
                                        attrs: { type: "EXPIRED" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }