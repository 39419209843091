var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SharedPrizeIterator",
    _vm._g(
      _vm._b(
        {
          attrs: { clickable: _vm.clickable, filterProps: _vm.filterProps },
          scopedSlots: _vm._u(
            [
              _vm.hasSlot("header")
                ? {
                    key: "header",
                    fn: function(props) {
                      return [_vm._t("header", null, null, props)]
                    }
                  }
                : null,
              {
                key: "card-actions",
                fn: function(props) {
                  return [
                    _vm._t(
                      "card-actions",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              block: "",
                              depressed: "",
                              color: _vm.isLightTheme ? "success" : "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.buy(props)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("actions.buy")))]
                        )
                      ],
                      null,
                      props
                    )
                  ]
                }
              },
              {
                key: "card-absolute",
                fn: function(props) {
                  return [
                    _vm._t(
                      "card-absolute",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", disabled: "" },
                            on: {
                              click: function($event) {
                                return _vm.onFavorite(props)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("fas fa-heart")])],
                          1
                        )
                      ],
                      null,
                      props
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        "SharedPrizeIterator",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }