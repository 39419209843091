<template>
    <SharedPrizeIterator
        v-bind="attrs"
        v-on="listeners"
        :clickable="clickable"
        :filterProps="filterProps"
    >
        <template #header="props" v-if="hasSlot('header')">
            <slot name="header" v-bind="props"></slot>
        </template>

        <template #card-actions="props">
            <slot name="card-actions" v-bind="props">
                <v-btn block depressed :color="isLightTheme ? 'success' : 'primary'" @click="buy(props)">{{ $t('actions.buy') }}</v-btn>
            </slot>
        </template>
        <template #card-absolute="props">
            <slot name="card-absolute" v-bind="props">
                <v-btn icon @click="onFavorite(props)" disabled><v-icon>fas fa-heart</v-icon></v-btn>
            </slot>
        </template>
    </SharedPrizeIterator>
</template>
<script>
import HasSlot             from '@/shared/mixins/HasSlot'
import RateColor           from '@/shared/mixins/RateColor'
import SharedPrizeIterator from '@/shared/components/iterators/PrizeIterator'

export default {
    name: 'PrizeIterator',
    props: {
        clickable: {
            type: Boolean,
            required: false,
            defualt: false,
        },
    },
    components: { SharedPrizeIterator },
    mixins: [ HasSlot, RateColor ],
    inheritAttrs: false,
    data: () => ({
        filterProps: {
            showFilter: true,
        },
    }),
    methods: {
        buy(props) {
            this.$emit('buy', props)
        },
        onFavorite(props) {
            this.$emit('favorite', props)
        },
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
}
</script>