var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("VoucherIterator", {
            ref: "iterator",
            attrs: {
              fetchFunction: _vm.fetchVouchers,
              showActions: _vm.type == "WITHDRAWABLE",
              zeroValueText: this.$t("views.Prizes.tabs.list.free"),
              colProps: { cols: 6, sm: 4, md: 3, lg: 2 },
              perPage: _vm.perPage
            },
            on: { error: _vm.errorHandler }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }