var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseIterator",
    _vm._g(
      _vm._b(
        {
          ref: "base",
          attrs: { filterProps: _vm.filterProps },
          on: {
            "show-in-list": function($event) {
              _vm.showInList = !_vm.showInList
            }
          },
          scopedSlots: _vm._u([
            {
              key: "items",
              fn: function(ref) {
                var item = ref.item
                return [
                  !_vm.showInList
                    ? _c("VoucherCard", {
                        attrs: {
                          voucher: item,
                          priceText: _vm.isCardValue(item),
                          showActions: _vm.showActions
                        }
                      })
                    : _c("VoucherCardMinimalist", {
                        attrs: {
                          voucher: item,
                          priceText: _vm.isCardValue(item),
                          showActions: _vm.showActions
                        }
                      })
                ]
              }
            }
          ])
        },
        "BaseIterator",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }