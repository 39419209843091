var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AlertModal", { ref: "alert" }),
      _c("LoadingModal", { ref: "loading" }),
      _c("ConfirmationModal", {
        ref: "confirmation",
        on: { confirm: _vm.buy }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("PrizeIterator", {
                attrs: {
                  fetchFunction: _vm.fetchPrizes,
                  colProps: { cols: 6, sm: 4, md: 3, lg: 2 },
                  zeroValueText: this.$t("views.Prizes.tabs.list.free"),
                  perPage: _vm.perPage,
                  clickable: true
                },
                on: {
                  error: _vm.errorHandler,
                  buy: _vm.confirmBuy,
                  "on-prize-click": _vm.goPrizeDetails
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }