<template>
    <v-container>
        <AlertModal ref="alert" />
        <LoadingModal ref="loading" />
        <ConfirmationModal ref="confirmation" @confirm="buy" />

        <v-row>
            <v-col cols="12">
                <PrizeIterator
                    :fetchFunction="fetchPrizes"
                    @error="errorHandler"
                    @buy="confirmBuy"
                    :colProps="{ cols: 6, sm: 4, md: 3, lg: 2 }"
                    :zeroValueText="this.$t('views.Prizes.tabs.list.free')"
                    :perPage="perPage"
                    @on-prize-click="goPrizeDetails"
                    :clickable="true"
                >
                </PrizeIterator>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import PrizeIterator from '@/components/iterators/PrizeIterator'
import LoadingModal from '@/components/modals/LoadingModal'
import AlertModal from '@/components/modals/AlertModal'
import Prize from '@/models/Prize'
import _ from 'lodash'

export default {
    name: 'PrizesList',
    components: { PrizeIterator, AlertModal, LoadingModal, ConfirmationModal },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        prize: null,
    }),
    methods: {
        async fetchPrizes(pagination, filter = {}) {
            this.loading = true
            let prizes = await Prize.list({ 
                params: {
                    ...pagination,
                    store: 'list_store',
                    'prize-name': _.get(filter, 'search_parameter', ''),
                }
            })
            this.loading = false
            return prizes
        },
        confirmBuy(props) {
            this.prize = new Prize(props.prize)
            this.$refs.confirmation.showConfirmation(this.$t('modals.ConfirmationModal.text'))
        },
        async buy() {            
            this.$refs.loading.open()

            let response = await this.prize.buy()
                .catch(this.preErrorHandler)

            this.$refs.loading.close()

            if (!response)
                return

            this.$emit('bought')

            await this.$refs.alert.showSuccess(this.$t('views.Prizes.tabs.list.alerts.buy_success'))
        },
        async preErrorHandler(e) {
            let errType = this.$lodash.get(e, 'response.data.type')

            errType = ['buy_error', 'insufficient_funds', 'no-code-available'].includes(errType) ? errType : 'buy_error'

            await this.$refs.alert.showError(this.$t(`views.Prizes.tabs.list.alerts.${errType}`))
        },
        goPrizeDetails(prize) {
            const id = prize.id
            this.$router.push({ name: 'prizes.details', params: { id } })
        },
    },
    computed: {
        perPage() {
            return this.$vuetify.breakpoint.lgAndUp ? 12  :
                   this.$vuetify.breakpoint.mdAndUp ? 12  :
                   this.$vuetify.breakpoint.smAndUp ? 12 : 10        
        }
    },
}
</script>