<template>
    <BaseModal 
        ref="modal" 
        :title="title" :hasSubtitle="false" :persistent="true" 
        :hasCancel="false" :hasConfirm="false" 
        max-width="400px" 
        @closed="$emit('closed')"
    >
        <template #text>
            <v-progress-circular indeterminate />
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'

export default {
    components: {
        BaseModal
    },    
    data: vm => {
        return {
            title: vm.$t('modals.LoadingModal.wait'),
        }
    },
    methods: {
        open() {
            this.$emit('opening')
            this.$refs.modal.open()
            this.$emit('opened')
        },
        close() {
            this.$emit('closing')
            this.$refs.modal.close()
            this.$emit('closed')
        },
    },
}
</script>