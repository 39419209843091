<template>
    <BaseIterator
        ref="base"
        v-bind="attrs"
        v-on="listeners"
        :filterProps="filterProps"
        @show-in-list="showInList = !showInList"
    >
        <template #items="{ item }">
            <VoucherCard
                v-if="!showInList"
                :voucher="item"
                :priceText="isCardValue(item)"
                :showActions="showActions"
            ></VoucherCard>
            <VoucherCardMinimalist
                v-else
                :voucher="item"
                :priceText="isCardValue(item)"
                :showActions="showActions"
            ></VoucherCardMinimalist>
        </template>
    </BaseIterator>
</template>

<script>
import BaseIterator from '@/shared/components/iterators/BaseIterator'
import VoucherCard from '@/components/cards/VoucherCard'
import VoucherCardMinimalist from '../cards/VoucherCardMinimalist.vue'

export default {
    name: 'VoucherIterator',
    components: { BaseIterator, VoucherCard, VoucherCardMinimalist },
    inheritAttrs: false,
    props: {
        showActions: {
            type: Boolean,
            default: false,
        },
        zeroValueText: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        filterProps: {
            showFilter: true,
            showIcon: true,
            iconName: 'mdi-eye',
            iconFunction: 'show-in-list',
            iconClass: 'd-flex d-sm-none',
        },
        showInList: false
    }),
    methods: {
        refresh() {
            this.$refs.base.refresh()
        },

        isCardValue(item) {
            return !item.prize.value ? this.zeroValueText : undefined
        }
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },

        showDefaultCard() {
            return this.$vuetify.breakpoint.mdAndUp && false
        },

        shouldShowFilter() {
            return this.showActions
        }
    }
}
</script>