var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto pa-2 py-0" },
    [
      _c(
        "v-row",
        { staticClass: "py-0" },
        [
          _c(
            "v-col",
            { staticClass: "align-center py-0 px-2", attrs: { cols: "8" } },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex" },
                [
                  _c("span", { staticClass: "body-1 text-truncate" }, [
                    _vm._v(_vm._s(_vm.voucher.prize.name))
                  ]),
                  _c("v-spacer")
                ],
                1
              ),
              _c("v-card-subtitle", [
                _c("span", { staticClass: "caption" }, [
                  _vm._v(_vm._s(_vm.additionalText))
                ])
              ])
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "flex-grow-1 align-content-center py-0 px-2",
              attrs: { cols: "4" }
            },
            [
              _vm.showActions
                ? _c(
                    "v-card-subtitle",
                    { staticClass: "pa-0 text-center align-self-center" },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "my-2 px-4 align-self-center",
                          attrs: {
                            color: _vm.isLightTheme
                              ? "orange darken-1"
                              : "primary",
                            label: "",
                            outlined: ""
                          }
                        },
                        [
                          _c("span", { staticClass: "subtitle-1" }, [
                            _vm._v(_vm._s(_vm.voucher.getCodeString()))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }