<template>
    <v-row>
        <v-col cols="12">
            <VoucherIterator
                ref="iterator"
                :fetchFunction="fetchVouchers"
                :showActions="type == 'WITHDRAWABLE'"
                @error="errorHandler"
                :zeroValueText="this.$t('views.Prizes.tabs.list.free')"
                :colProps="{ cols: 6, sm: 4, md: 3, lg: 2 }"
                :perPage="perPage"
            >
            </VoucherIterator>
        </v-col>
    </v-row>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import VoucherIterator      from '@/components/iterators/VoucherIterator'
import Voucher              from '@/models/Voucher'
import { mapGetters }       from 'vuex'
import _                    from 'lodash'

export default {
    name: 'PrizesHistory',
    mixins: [ HasErrorHandlerMixin ],
    components: { VoucherIterator },
    props: {
        type: {
            type: String,
            required: true,
            validator: function (value) {
                return ['WITHDRAWABLE', 'WITHDRAWN', 'EXPIRED'].includes(value)
            },
        },
    },
    methods: {
        refresh() {
            this.$refs.iterator.refresh()
        },

        async fetchVouchers(pagination, filter = {}) {
            let params  = this.type != 'WITHDRAWABLE' ? { withdrawable: 'FALSE' } : {}
            let headers = {
                'customer-document': this.user && this.user.document,
                'prize-name': _.get(filter, 'search_parameter', ''),
            }

            return (await Voucher.list({ ...pagination, ...params }, { headers }))
                .filter(item => {
                    let voucher = new Voucher(item)

                    if (this.type == 'WITHDRAWABLE')
                        return true

                    if (this.type == 'WITHDRAWN' && voucher.isWithdrawn())
                        return true

                    if (this.type == 'EXPIRED' && voucher.isExpired())
                        return true

                    return false
                })
        },
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        perPage() {
            return this.$vuetify.breakpoint.lgAndUp ? 12  :
                   this.$vuetify.breakpoint.mdAndUp ? 12  :
                   this.$vuetify.breakpoint.smAndUp ? 12 : 10        
        },
    },
}
</script>