var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      title: _vm.title,
      hasSubtitle: false,
      persistent: true,
      hasCancel: false,
      hasConfirm: false,
      "max-width": "400px"
    },
    on: {
      closed: function($event) {
        return _vm.$emit("closed")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function() {
          return [_c("v-progress-circular", { attrs: { indeterminate: "" } })]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }